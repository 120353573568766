/*                                                            General styles                                         */




@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body{
  margin:0px;
  padding: 0px;
  background-color: #e6eff3;
  font-family: 'Lato', sans-serif;
}

*{
  box-sizing: border-box;
}


ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}




/*                                                                Navbar styles                          */



.logo img{
  width: 90px;
  border-radius: 20px;
  margin-top: 20px;
}


nav{
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	width:100%;
	z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}
nav.active{
	box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
	background-color: #000000;
}
nav ul{
	display: flex;
}
.active{
    background-color: #FF1414;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(198, 64, 64, 0.411);
	transition: all ease 0.2s;
}
.active:hover{
    background-color: #000000;
    color:#ffffff;
	box-shadow: 5px 10px 30px rgba(198, 64, 64, 0.411);
	transition: all ease 0.2s;
}
nav ul li a{
    font-family: 'Lato';
	height:40px;
	line-height: 43px;
	margin: 3px;
	padding: 0px 22px;
	display: flex;
	font-size: 0.9rem;
	text-transform: uppercase;
	font-weight: 500;
	color:#ffffff;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}
nav ul li a:hover{
	background-color: #04a6e6;
  color:#fdfdfd;
	box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
	transition: all ease 0.2s;
}
nav .menu-btn,
.menu-icon{
		display: none;
	}


/*                                                      Header styles                                         */
                      


#main {
    width: 100%;
    height: 768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('./edn images/airplane view 3.jpg');
    z-index: 0;
}

#main::before{
    position: absolute;
    content: "";

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.name{
    text-align: center;
	font-family: 'Montserrat';
	width: 900px;
	position: absolute;
    left: 50%;
	top: 55%;
	transform: translate(-50%,-55%);
}
.name span{
    color: #FF1414;
}
.name .details{
    font-size: 23px;
    color: #c5c5c5;
}
.name h2{
	font-family:'Lato';
	font-size: 2.7rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.name h1{
	font-family:'Lato';
	font-size: 5rem;
	margin:0px;
	letter-spacing: 2px;
	color:#ffffff;
}
.header-btns{
    display: flex;
    margin: 0px 0px 0px 0px;
}
.header-btn {
    width: auto; /* Allow the button to adjust its width based on content */
    display: inline-block; /* Display the button inline */
    padding: 10px 10px; /* Adjust padding as needed */
    margin: 0px 0px 0px 0px; /* Adjust margin as needed */
    font-size: 1.4rem;
    background-color: #FF1414;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border-radius: 5px;
    color: #ffffff;
    text-decoration: none; /* Remove underline from link */
    border: none; /* Remove border */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  }
  
  .header-btn:hover {
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #FF1414; /* Add border on hover */
  }
  

.header-heading {
    position: relative;
    width: 80%; /* Adjust width as needed */
    max-width: 900px; /* Set maximum width to prevent overflow */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add padding for spacing */
    text-align: center; /* Center-align the text */
    color: #ffffff; /* Set text color to white */
    display: flex; /* Use flexbox for centering */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
  }
  
  .header-heading h2 {
    font-size: 2rem; /* Adjust font size as needed */
    margin-bottom: 50px;
    margin-top: 100px; /* Add space between elements */
  }
  
  .header-heading h1 {
    font-size: 3.5rem; /* Adjust font size as needed */
    margin-bottom: 20px; /* Add space between elements */
  }
  
  .header-heading .details {
    font-size: 1.2rem; /* Adjust font size as needed */
    margin-bottom: 20px; /* Add space between elements */
    color: #ffffff; /* Set text color to white */
  }
  
  .header-btns {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    margin: 0px 0px 0px 0px; /* Add margin between text and button */
  }
  
  .header-btn {
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 1.4rem;
    background-color: #FF1414;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border-radius: 5px;
    color: #ffffff;
    text-decoration: none; /* Remove underline from link */
    border: none; /* Remove border */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
    margin-left: 0%;
    margin-right: 0%;
    padding: 5px 5px 5px 5px;
    align-items: center;
    align-self: center;
    align-content: center;
    margin: 0px 0px 0px 0px;
  }
  
  .header-btn:hover {
    background-color: transparent;
    color: #ffffff;
    border: 2px solid #FF1414; /* Add border on hover */
  }
  

span {
    color: #fff01e;
}






/*                                                                     Features                             */

#features {
  width: 100%;
  padding: 80px 0; /* Add padding for spacing */
  background-color: #e6eff3;
  text-align: center; /* Center-align the content */
}

#features h1 {
  color: #000000; /* Set text color */
  font-size: 3rem; /* Adjust font size as needed */
  margin-bottom: 40px; /* Add space between heading and content */
}

.a-container {
  display: flex;
  flex-wrap: wrap; /* Allow flex items to wrap */
  justify-content: center; /* Center items horizontally */
  gap: 40px; /* Add gap between feature boxes */
}

.a-box {
  position: relative;
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  text-align: center;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.a-box:hover {
  transform: scale(1.05);
}

.a-b-img img {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.a-b-text {
  padding: 20px;
}

.a-b-text h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.a-b-text .hidden-text {
  display: none;
  font-size: 1em;
  color: #333;
  transition: opacity 0.3s;
}

.a-box:hover .hidden-text,
.a-box:focus .hidden-text,
.a-box:active .hidden-text {
  display: block;
  opacity: 1;
}

.a-box:hover .a-b-text h2,
.a-box:focus .a-b-text h2,
.a-box:active .a-b-text h2 {
  margin-bottom: 20px;
}



/*                                                                 Offers                                */

/*#offer{
  padding-top: 30px;
  width:100%;
  height:768px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(images/offer.png);
  text-align: center;
}*/

.pr-heading{
  text-align: center;
font-family:'Lato';
width: 600px;
position: absolute;
  left: 50%;
top: 55%;
transform: translate(-50%,-55%);
}
.pr-heading span{
  color: #FF1414;
}
.pr-heading .details{
  font-size: 23px;
  color: #c5c5c5;
}
.pr-heading .details{
  font-size: 1.2rem;
}
.pr-heading h1{
font-family:'Lato';
font-size: 4rem;
margin:0px;
letter-spacing: 2px;
color:#ffffff;
}
.pr-btns{
  display: flex;
  margin-top: 40px;
  margin-left: 35%;
}
.pr-btn{
width:160px;
height: 50px;
display: flex;
justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  background-color: #FF1414;
  box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
  border-radius: 5px;
color:#ffffff;
}
.pr-btn:hover{
background-color: transparent;
  transition: all ease 0.5s;
  color: #ffffff;
  border: 2px solid #FF1414;
}

.no-offers-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.no-offers-message {
  background-color: #000000;
  color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}





/*                                                          About styles                                   */


#about {
  padding: 50px 20px;
  background-color: #f4f4f4;
}

.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.about-image {
  flex: 1;
  text-align: center;
 
}

.about-image img {
  max-width: 100%;
  height: 400px;
  border-radius: 10px;
}

.about-text {
  flex: 2;
  min-width: 300px;
}

.about-text h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.about-text button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.about-text button:hover {
  background-color: #ff4500;
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .about-image {
    order: -1;
  }

  .about-image img {
    max-width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }

  .about-text h1 {
    font-size: 2em;
  }

  .about-text p {
    font-size: 1em;
  }
}



/*                                                 Contact                                       */


.contact-header {
  color: black;
}


#contact{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#contact h1{
    color: #000000;
    font-size: 3rem;
    align-content: center;
    text-align: center;
}
#contact form{
    width:600px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
#contact form input,
#contact form textarea{
    width:100%;
    height:50px;
    margin:5px 0px;
    padding: 10px;
    border: 100px;
    border-color: #da0202;
    outline: solid;
    background-color: #ffffff2d;
    color: #030303;
    border-radius: 5px;
}
#contact form textarea{
    height: 150px;
}
#contact form input[type="submit"]{
    height: 45px;
    background: linear-gradient(90deg, #FF1414 20%,#f70000);
    color: #ebb3b3;
    text-transform: uppercase;
}

@media(max-width:620px){
  #contact form{
      width:90%;
  }
}


/*                                                  Footer                                                             */

.footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0%;
  }
  
  .social-media, .contact-info {
    display: flex;
    flex-direction: row ;
    align-items: center;
    margin: 0px 0px;
    padding: 20px 20px 5px 20px;
  }
  
  .social-media a, .contact-info a {
    color: #fff;
    text-decoration: none;
    margin: 5px 0;
    font-size: 1.2em;
    transition: color 0.3s;
    margin: 20px 20px 5px 20px;
    
  }
  
  .social-media a:hover, .contact-info a:hover {
    color: #ff6347; /* Tomato color on hover */
  }
  
  .social-media i, .contact-info i {
    margin-right: 8px;
  }
  
  .contact-info p {
    margin: 20px 20px 5px 20px ;
    font-size: 1.1em;
  }
  
  @media (max-width: 600px) {
    .footer-container {
      flex-direction: column;
    }
  
    .social-media, .contact-info {
      margin: 10px 0;
    }
  }
  





/* Styles to accommodate the changing screens */

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

	.name{
		width:60%;
    }
    .pr-heading{
		width:60%;
    }
    .a-box{
      width: 100%;
      height:400px;
  }

  .menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .pr-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#000000;
            border-bottom:4px solid #FF1414;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#ffffff;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:24px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:rgb(255, 255, 255);
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:6px;
        }
        nav .menu-icon .nav-icon:after{
            top:-6px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }
        .social-media, .contact-info {
          display: flex;
          flex-direction: column ;
          align-items: center;
          margin: 0%;
        }
        
        .social-media a, .contact-info a {
          color: #fff;
          text-decoration: none;
          margin: 5px 0;
          font-size: 1.2em;
          transition: color 0.3s;
          margin: 20px 20px 5px 20px;
          
        }



        .about-image {
          flex: 1;
          text-align: center;
         
        }
        
        .about-image img {
          max-width: 60%;
          height: 50%;
          border-radius: 20px;
        }
        
        
        .social-media a:hover, .contact-info a:hover {
          color: #ff6347; /* Tomato color on hover */
        }
        
        .social-media i, .contact-info i {
          margin-right: 8px;
        }
        
        .contact-info p {
          margin: 2px 0px;
          font-size: 1.1em;
        }
      }



        @media(max-width:400px){
            .a-box{
                width: 100%;
                height:400px;
            }
            .social-media, .contact-info {
              display: flex;
              flex-direction: column ;
              align-items: center;
              margin: 0%;
            }
            
            .social-media a, .contact-info a {
              color: #fff;
              text-decoration: none;
              margin: 5px 0;
              font-size: 1.2em;
              transition: color 0.3s;
              margin: 20px 20px 5px 20px;
              
            }

            .about-image {
              flex: 1;
              text-align: center;
             
            }
            
            .about-image img {
              max-width: 60%;
              height: 40%;
              border-radius: 20px;
            }
            
            .social-media a:hover, .contact-info a:hover {
              color: #ff6347; /* Tomato color on hover */
            }
            
            .social-media i, .contact-info i {
              margin-right: 8px;
            }
            
            .contact-info p {
              margin: 2px 0px;
              font-size: 1.1em;
            }
  
        }



/* Adjust the font sizes and styles to keep "WELCOME TO" at the top and "EDN LOGISTICS AND TRAVEL" on one line */
.header-heading h2 {
  font-size: 2.5rem; /* Adjust font size as needed */
  margin-bottom: 10px; /* Add space between elements */
}

.header-heading h1 {
  font-size: 3.5rem; /* Adjust font size as needed */
  margin-bottom: 20px; /* Add space between elements */
}

/* Ensure text fits in the header for smaller window sizes */
@media (max-width: 768px) {
  .header-heading h2 {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }
  .header-heading h1 {
    font-size: 3rem; /* Adjust font size for smaller screens */
  }
  .header-heading .details {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}

/* Align the booking button in the center of the screen on smaller devices */
@media (max-width: 480px) {
  .header-btns {
    justify-content: center; /* Center the button horizontally */
    margin-top: 20px; /* Add margin to separate from other elements */
  }
  
}

/* max-width: 970px */
@media only screen and (min-width: 600px) and (max-width: 970px) {
  #main {
    height: 650px;
  }
  .name, .pr-heading {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .header-btn {
    margin-left: 0%;
    margin-top: 0px;
  }
  .pr-btn {
    margin-left: 20%;
    margin-top: 20px;
  }
  nav .menu-btn,
.menu-icon{
		display: block;
	}
  .menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .pr-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            background-color:#000000;
            border-bottom:4px solid #FF1414;
            width:100%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            align-items: center;
            margin:0px;
            padding: 25px;
            border:1px solid rgba(38,38,38,0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#ffffff;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:24px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:rgb(255, 255, 255);
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:6px;
        }
        nav .menu-icon .nav-icon:after{
            top:-6px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
        @media(max-width:400px){
            .a-box{
                width: 100%;
                height:400px;
            }

            
        }



/* Extra large devices (desktops, 970px and up) */
@media only screen and (min-width: 970px) and (max-width: 1200px) {
  #main {
    background-size: 1100px !important;
  }
  .name, .pr-heading {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .name h2 {
    font-size: 1.5rem;
  }
  .name h1 {
    font-size: 3rem;
  }
  .name .details {
    font-size: 1rem;
  }
  .pr-heading h2, .pr-heading h1 {
    font-size: auto;
    text-align: justify;
  }
  .pr-heading .details {
    font-size: 1rem;
  }
  #features {
    height: auto;
    margin-bottom: 100px;
  }
  .a-container {
    flex-wrap: wrap;
  }
  .a-box {
    flex-grow: 1;
  }
  .a-b-img img {
    object-fit: contain;
  }
  .header-btn {
    margin-top: 20px;
  }
  .about-image {
    flex: 1;
    text-align: center;

    width: 300px;
    height: fit-content;
    padding: 0px 0px 0px 0px;
    size: cover;
   
  }
  
  .about-image img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 0px 0px 0px 0px;
  }
  
}

/* Extra extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
 
  .a-box {
    width: 100%;
    height: 400px;
  }
  .name {
    width: 60%;
  }
  .pr-heading {
    width: 60%;
  }
  .header-heading{
    padding-top: 50px;
  }
  .header-btn{
    margin-top: 100px;
    padding: 0%;
  }
  .details{
    padding: 50px 50px 50px 50px;
    size: 100px;

    }
  }





/*                                             CSS ANIMATION                                                 */

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

